import React from 'react';
import styles from "@kursk/components/Layout/AuthModal/SignUpForm/SignUpForm.module.scss";
import { Input } from "@kursk/components/ui/Input/Input";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { RadioButton } from "@kursk/components/ui/RadioButton/RadioButton";
import style from "@kursk/components/ui/BaseSubscribeForm/BaseSubscribeForm.module.scss";
import { Checkbox } from "@kursk/components/ui/Checkbox/Checkbox";
import { regionContent } from "@kursk/content/regionContent";
import { Button } from "@kursk/components/ui/Button/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { getConfig } from "@root/config/config";

const config = getConfig();

export const BaseSignUpForm = ({
  children,
  onSubmit,
  handleSubmit,
  register,
  genderControl,
  disabledRegisterBtn,
  goToLoginForm,
  errors,
  isPhoneSignUpForm,
  reloadCaptcha,
  setReloadCaptcha
}) => {
  const [captchaValue, setCaptchaValue] = React.useState<string | undefined>();
  const captchaRef = React.useRef(null);

  React.useEffect(() => {
    if (isPhoneSignUpForm && reloadCaptcha && captchaRef?.current) {
      captchaRef.current.reset();
      setReloadCaptcha(false);
    }
  }, [isPhoneSignUpForm, captchaRef, reloadCaptcha])

  return (
    <form onSubmit={handleSubmit(() => onSubmit(captchaValue))}>
      {children}
      <h1 className={classNames(styles.header, styles.header_subtitle)}>Данные пользователя</h1>
      <label className={styles.label}>
        Имя
      </label>
      <div className={styles.input_wrapper}>
        <Input
          {...register("name")}
          errors={errors}
        />
      </div>
      <label className={styles.label}>
        Пол
      </label>
      <Controller
        name="gender"
        control={genderControl}
        render={({ field: { onChange, value } }) => (
          <div className={styles.radio_group}>
            <RadioButton value={'none'} checked={value === 'none'} onChange={onChange} title={'Не выбрано'}/>
            <RadioButton value={'male'} checked={value === 'male'} onChange={onChange} title={'Мужской'}/>
            <RadioButton value={'female'} checked={value === 'female'} onChange={onChange} title={'Женский'}/>
          </div>
        )}
      />

      <div className={classNames(
        style.checkboxWrapper,
        style.checkboxWrapper__output,
        isPhoneSignUpForm ? style.checkboxWrapper__phone : ''
      )}>
        <Checkbox {...register('agree')}
          error={!confirm}
          errors={errors}>
          <span className={style.agreementText}>Я подтверждаю свое согласие с {' '}
            условиями{' '}
            <a className={'withLine'}
              target="_blank"
              href={regionContent.agreementLink}
              rel="noreferrer">пользовательского соглашения</a>
          </span>
        </Checkbox>
      </div>
      {!!isPhoneSignUpForm && config.reCaptchaKey ? (
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={config.reCaptchaKey}
          onChange={(capVal) => setCaptchaValue(capVal)}
          hl='ru'
        />
      ) : null}
      <Button
        text='ЗАРЕГИСТРИРОВАТЬСЯ'
        buttonType="greenPrimary"
        type='submit'
        className={isPhoneSignUpForm ? styles.signup : undefined}
        disabled={isPhoneSignUpForm ? !captchaValue || disabledRegisterBtn : disabledRegisterBtn}
      />
      <Button
        text='ВОЙТИ'
        onClick={goToLoginForm}
        buttonType='ghostButton'
        className={styles.signin}
      />
    </form>
  );
};
